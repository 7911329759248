import React from 'react'
import { Button,Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import userColumns from '../../../assets/data/userColumns';
import ReactTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import CustomizerContext from '../../../_helper/Customizer';
export default function OnlineUsersModal({modal,toggle,users, ...args}) {
    const navigate = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
    const handleRowClick = (row) => {
      navigate(`${process.env.PUBLIC_URL}/pages/users/${layoutURL}/${row.id}`, { state: { user: row } });
  };
  const conditionalRowStyles = [
    {
      when: row => true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },

  ];

  return (
    <div>
        <Modal isOpen={modal} toggle={toggle} size="lg" {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
          <ReactTable
                    columns={userColumns}
                    data={users}
                    noHeader
                    pagination
                    highlightOnHover
                    subHeader
                    onRowClicked={handleRowClick}
                    conditionalRowStyles= {conditionalRowStyles}
                />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle} type="button">
              Ascunde
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  )
}

