import React from 'react'
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import userService from '../../../Services/UserService';

export default function SubscriptionActivationModal({handleSubmit,modal,toggle,transactionId, ...args}) {

  const handleDelete = async () => {
    try {
      const response = await userService.deleteTransaction(transactionId);
      toast.success('Tranzactie stearsa cu succes!', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Eroare la stergere tranzactie', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <H5>Activeaza abonamentul pentru tranzactia: {transactionId}</H5>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>
            Activeaza
            </Button>{' '}
            <Button color="primary" onClick={handleDelete}>
            Sterge
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
}
