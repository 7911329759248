import React, { useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import { useNavigate } from 'react-router';
import ReactTable from 'react-data-table-component';
import userColumns from '../../../assets/data/userColumns';
import { useAppData } from '../../../context/AppDataContext';
import FilterComponent from '../../Common/Component/FilterComponent';
import CustomizerContext from '../../../_helper/Customizer';
import { useContext } from 'react';

export default function UsersTable() {
  const [loading, setLoading] = useState(false);
  const { users } = useAppData();
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterBy, setFilterBy] = useState('name');
  const [selectedColumns, setSelectedColumns] = useState(userColumns.map(col => col.name));  // Use 'name' instead of 'selector'
  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const handleRowClick = (row) => {
    navigate(`${process.env.PUBLIC_URL}/pages/users/${layoutURL}/${row.id}`);
  };

  const enabledUserCount = () => {
    if (!users) return 0;
    const enabledUsers = users.filter(user => user.enabled === true);
    return enabledUsers.length;
  };

  const conditionalRowStyles = [
    {
      when: row => true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    if (filterBy === "name") return users.filter(user => user.name && user.name.toLowerCase().includes(filterText.toLowerCase()));
    if (filterBy === "email") return users.filter(user => user.email && user.email.toLowerCase().includes(filterText.toLowerCase()));
    if (filterBy === "telephoneNumber") return users.filter(user => user.telephoneNumber && user.telephoneNumber.toLowerCase().includes(filterText.toLowerCase()));
    if (filterBy === "IDNO") return users.filter(user => user.idno && user.idno.toLowerCase().includes(filterText.toLowerCase()));
    if (filterBy === "IDNO") return users.filter(user => user.lastTransactionId && user.lastTransactionId.toLowerCase().includes(filterText.toLowerCase()));
    if (filterBy === '') return users;
  }, [users, filterText]);

  const handleClearFilter = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = useMemo(() => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Input
        className='w-auto'
        type="select"
        name="filterBy"
        value={filterBy}
        onChange={(e) => setFilterBy(e.target.value)}
      >
        <option value="name">Nume</option>
        <option value="email">Email</option>
        <option value="telephoneNumber">Telefon</option>
        <option value="IDNO">IDNO</option>
        <option value="lastTransactionId">Ultimul cont de plata</option>
      </Input>
      <FilterComponent 
        onFilter={e => setFilterText(e.target.value)} 
        onClear={handleClearFilter} 
        filterText={filterText} 
        filterBy={filterBy}
      />
    </div>
  ), [filterText, resetPaginationToggle, filterBy]);

  // Dynamically filter the columns based on selectedColumns
  const columns = useMemo(() => {
    return userColumns.filter(column => selectedColumns.includes(column.name));  // Filter by 'name'
  }, [selectedColumns]);

  const handleColumnChange = (e) => {
    const { value, checked } = e.target;

    console.log(`Column selected: ${value}, Checked: ${checked}`);

    // Update the selectedColumns based on checkbox state
    setSelectedColumns(prevState => {
      if (checked) {
        return [...prevState, value];  // Add column to the state
      } else {
        return prevState.filter(col => col !== value);  // Remove column from the state
      }
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h5>Utilizatori</h5>
          <p>Utilizatori reali: {enabledUserCount()}</p>
        </CardHeader>
        <CardBody>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <h6>Select Columns:</h6>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}> 
                {userColumns.map((col) => (
                  <div key={col.name} style={{ display: 'block', alignItems: 'center' }}> 
                    <input
                      type="checkbox"
                      id={col.name}
                      value={col.name}  
                      checked={selectedColumns.includes(col.name)} 
                      onChange={handleColumnChange}
                      style={{ marginRight: '10px', transform: 'scale(1.3)' }} // Larger checkbox size
                    />
                    <label htmlFor={col.name} style={{ fontSize: '14px', color: '#555' }}>{col.name}</label> 
                  </div>
                ))}
              </div>
            </div>
            <ReactTable
              columns={columns}
              data={filteredUsers}
              noHeader
              pagination
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              onRowClicked={handleRowClick}
              conditionalRowStyles={conditionalRowStyles}
              paginationPerPage={10} 
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}
