import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useAppData } from '../../../context/AppDataContext';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import OnlineUsersModal from '../Users/OnlineUsersModal';

const WidgetsWrapper = () => {
  const { transactions, users,subscriptions,totalCalculations } = useAppData();
  const [onlineUsersModal, setOnlineUsersModal] = useState(false);
  const toggleOnlineUsersModal = () => {setOnlineUsersModal(!onlineUsersModal);};

  const totalAmount = () =>{ return transactions.reduce((acc, transaction) => {
    return transaction.status === 'successful' ? acc + transaction.amount : acc;
  }, 0);
};


const tokenAmountProfit = () =>{ return transactions.reduce((acc, transaction) => {
  return transaction.scope == 'tokens' && transaction.status === 'successful' ? acc + transaction.amount : acc;
}, 0);
};
const subscriptionAmountProfit = () =>{ return transactions.reduce((acc, transaction) => {
  return transaction.scope == 'subscription' && transaction.status === 'successful' ? acc + transaction.amount : acc;
}, 0);
};
  const enabledUserCount = () => {
    if (!users) return 0; // Return 0 if data is null

    // Filter the users based on the 'enabled' field
    const enabledUsers = users.filter(user => user.enabled === true);
    return enabledUsers.length; // Return the count of enabled users
};
const onlineUsersCount = () => {
  if (!users) return 0; // Return 0 if data is null

  // Filter the users based on the 'enabled' field
  const onlineUsers = users.filter(user => user.currentActiveSessions != 0);
  return onlineUsers.length; // Return the count of enabled users
};
const onlineUsers = () => {
  if(!users) return 0;

  const onlineUsers = users.filter(user => user.currentActiveSessions != 0);
  return onlineUsers;
}
const enabledSubscriptionCount = () => {
  if (!subscriptions || !Array.isArray(subscriptions)) return 0;

  // Filter the users based on the 'enabled' field
  const enabledSubscriptions = subscriptions.filter(subscription => subscription.status == "active");
  return enabledSubscriptions.length; // Return the count of enabled users
};
const daysFromStartCount = ()=>{
  const startDate = new Date(2024, 4, 14); // Adjust month (0-indexed)
  const today = new Date();
  const timeDifference = today.getTime() - startDate.getTime();
  const daysSinceStart = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days

  return daysSinceStart;
}
  const WidgetsData = {
    title: 'Incasari',
    gros: 100,
    total: totalAmount(),
    color: 'secondary',
    icon: 'profit',
  };
  const WidgetsData2 = {
    title: 'Utilizatori activi',
    gros: 100,
    total: enabledUserCount(),
    color: 'secondary',
    icon: 'customers',
  };
  const WidgetsData3 = {
    title: 'Abonamente active',
    gros: 100,
    total: enabledSubscriptionCount(),
    color: 'secondary',
    icon: 'course-1',
  };
   const WidgetsData4 = {
    title: 'Zile de la start',
    gros: 100,
    total: daysFromStartCount(),
    color: 'secondary',
    icon: 'fill-calender',
  };
  const WidgetsData5 = {
    title: 'Utilizatori online',
    gros: 100,
    total: onlineUsersCount(),
    color: 'secondary',
    icon: 'user-visitor',
  };
  const WidgetsData6 = {
    title: 'Incasari Tokenuri',
    gros: 100,
    total: tokenAmountProfit(),
    color: 'secondary',
    icon: 'income',
  };
  const WidgetsData7 = {
    title: 'Incasari Abonamente',
    gros: 100,
    total: subscriptionAmountProfit(),
    color: 'secondary',
    icon: 'income',
  };
  const WidgetsData8 = {
    title: 'Total Calcule',
    gros: 100,
    total: totalCalculations,
    color: 'secondary',
    icon: 'target',
  };
  return (
    <>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetsData}/>
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData7} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData5} onClick={toggleOnlineUsersModal} style={{cursor: "pointer"}} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData2} />
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetsData6} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData8} />
          </Col>
        </Row>
      </Col>
      <OnlineUsersModal
       modal={onlineUsersModal}
       toggle={toggleOnlineUsersModal}
       users = {onlineUsers()}
      />
    </>
  );
};

export default WidgetsWrapper;
