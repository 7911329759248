import moment from "moment";
import { Link } from "react-router-dom";

const userColumns = [
  {
    name: 'ID',
    selector: row => row.id,
    sortable: true,
    center: false,

  },
  {
    name: 'Nume',
    selector: row => row.name,
    sortable: true,
    center: false,
    width:"20%",
  },
  {
    name: 'Email',
    selector: row => row.email,
    sortable: true,
    center: false,
  },
  {
    name: 'Data Creării',
    selector: row => moment(row.creationDate).format('DD.MM.YYYY'),
    sortable: true,
    sortFunction: (a, b) => new Date(a.creationDate) - new Date(b.creationDate),
    center: false,
  },
  {
    name: 'Activ',
    selector: row => row.enabled ? 'Da' : 'Nu',
    sortable: true,
    center: false,
  },
  
  {
    name: 'Sesiuni active',
    selector: row => `${row.currentActiveSessions}/${row.maxActiveSessions == 0 ? "1" : row.maxActiveSessions}`, 
    sortable: true,
    center: false,
  },
  {
    name: 'Tokenuri',
    selector: row => row.balance, 
    sortable: true,
    center: false,
  },
  {
    name: 'Calcule',
    selector: row => row.numberOfCalcs, 
    sortable: true,
    center: false,
  },
  {
    name: 'Tip Persoană',
    selector: row => row.personType ? 'Juridica' : 'Fizica',
    sortable: true,
    center: false,
  },
  {
    name: 'Număr de Telefon',
    selector: row => <Link to={`tel:${row.telephoneNumber}`}>
    {row.telephoneNumber}
  </Link>,
    ignoreRowClick: true,
    sortable: true,
    center: false,
  },

  {
    name: 'IDNO',
    selector: row => row.idno,
    sortable: true,
    center: false,
  },
  {
    name: 'Rol',
    selector: row => row.role, 
    sortable: true,
    center: false,
  },
  {
    name: 'Asociat',
    selector: row => row.linked ? 'Da' : 'Nu',
    sortable: true,
    center: false,
  },
  {
    name: 'Ultimul cont de plata',
    selector: row => row.lastTransactionId,
    sortable: true,
    center: false,
  },
];

export default userColumns;
