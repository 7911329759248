import React, { useState } from 'react'
import { Edit, Save, X } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Col, Container, Input, Row, } from 'reactstrap';
import { useAppData } from '../../../context/AppDataContext';
import userService from '../../../Services/UserService';
import CalculationsModal from './CalculationsModal';
import GenerateTokensModal from './GenerateTokensModal';
import SubscriptionModal from './SubscriptionModal';
import SuspendUserModal from './SuspendUserModal';
import TransactionsModal from './TransactionsModal';

export default function UserCard({user, refetchUser}) {
    const [generateTokensModal, setGenerateTokensModal] = useState(false);
    const [suspendUserModal, setSuspendUserModal] = useState(false);
    const [calculationsModal, setCalculationsModal] = useState(false);
    const [transactionsModal, setTransactionsModal] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const toggleSubscriptionModal = () => setSubscriptionModal(!subscriptionModal);
    const toggleTransactionsModal = () => setTransactionsModal(!transactionsModal);
    const toggleCalculationsModal = () => setCalculationsModal(!calculationsModal);
    const toggleSuspendUserModal = () => setSuspendUserModal(!suspendUserModal);
    const toggleGenerateTokensModal = () => setGenerateTokensModal(!generateTokensModal);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const {users,setUsers} = useAppData();
    const [formData, setFormData] = useState({
      name: user.name,
      telephoneNumber: user.telephoneNumber,
      maxActiveSessions: user.maxActiveSessions,
      maxSubUsers: user.maxSubUsers,
      idno: user.idno,
      personType: user.personType,
    });
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
    const handleToggleEdit = () => {
      setIsEditing(!isEditing);
    };

    const handleSave = async () => {
      try {
        await userService.updateUserDetails(user.id, formData);
        toast.success('User details updated successfully!');
        refetchUser();
        setIsEditing(false);
      } catch (error) {
        toast.error('Failed to update user details.');
      }
    };
    const handleCancel = () => {
      setIsEditing(false);
      setFormData({
        name: user.name,
        telephoneNumber: user.telephoneNumber,
        maxActiveSessions: user.maxActiveSessions,
        maxSubUsers: user.maxSubUsers,
        idno: user.idno,
        personType: user.personType,
      });
    };
    const notifyUser = () =>{
      navigate(`${process.env.PUBLIC_URL}/pages/notify/:layout?userId=${user.id}`);
    }
    const activateSuperAccount = async (e) => {
      e.preventDefault();
      try {
          const response = await userService.setupSuperAccount(user.id);
          toast.success('Supercont activat cu succes!', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            refetchUser();
      } catch (error) {
          toast.error('Eroare la activare supercont!', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
      }
  }
  const handleUserDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await userService.deleteUser(user.id);
      toast.success('Utilizator eliminat cu succes!', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setUsers(prevUsers => prevUsers.filter(u => u.id !== user.id));
    } catch (error) {
      toast.error('Eroare la eliminare utilizator!', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const cancelSuperAccount = async (e) => {
    e.preventDefault();
    try {
        const response = await userService.cancelSuperAccount(user.id);
        toast.success('Supercont dezactivat cu succes!', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          refetchUser();
    } catch (error) {
        toast.error('Eroare la dezactivare supercont!', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }
}
    const cancelSubscription = ()=>{
        try {
            const response = userService.cancelSubscription(user.id);
            toast.success('Abonament anulat cu succes!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              refetchUser();
        } catch (error) {
            toast.error('Anularea abonamentului a esuat!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
    

  return (
    <Container>
     <Card>
        <CardBody style={{ width: '100%' }}>
            {isEditing ? ( <>
             <Row><Col> <CardText>
              <strong>Nume:</strong>
              <Input
              className='w-auto'
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}  
            />
                      <div 
            style={{
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0, 
              background: 'none',
              border: 'none',
              verticalAlign: 'middle',
              paddingLeft: "5px",
              float: "right"
            }}
            onClick={handleCancel}
          >
            <X size={20} />
          </div>
            <div 
            style={{
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0, 
              background: 'none',
              border: 'none',
              verticalAlign: 'middle',
              paddingLeft: "5px",
              float: "right"
            }}
            onClick={handleSave}
          >
            <Save size={20} />
          </div>
          </CardText></Col></Row>
            </>
            ) :<>
            <CardTitle tag="h5">
            {user.name}
            <div 
            style={{
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0, 
              background: 'none',
              border: 'none',
              verticalAlign: 'middle',
              paddingLeft: "5px",
              float: "right"
            }}
            onClick={handleToggleEdit}
          >
            <Edit size={20} />
          </div>
          </CardTitle></> }
              <Row className="d-none d-md-flex">
              {!isEditing &&
              <Row>
              <Col><CardText><strong>Email:</strong> {user.email}</CardText></Col>
              <Col><CardText><strong>Rol:</strong> {user.role}</CardText></Col>
              <Col><CardText><strong>Tokenuri:</strong> {user.balance}</CardText></Col>
             </Row>}
               {!isEditing && 
               <Row>
               <Col><CardText ><strong>Status abonament:</strong> {user.subscriptionStatus ? "Activ: " + (user.subscription.plan.id == "lun20" ? "Lunar" :user.subscription.plan.id == "sem80" ? "Semestrial" : "Anual") : user.subscription && user.subscription.status  == "pending" ? "In asteptare" : "Inactiv"}</CardText></Col>
               <Col><CardText><strong>Ultima autentificare:</strong> {new Date(user.lastLoginTime).toLocaleString()}</CardText></Col>
               <Col><CardText><strong>Data creării:</strong> {new Date(user.creationDate).toLocaleString()}</CardText></Col>
              </Row>}
               <Row>
                <Col><CardText><strong>Telefon:</strong>{isEditing ? (  <Input
              className='w-auto'
              type="text"
              name="telephoneNumber"
              value={formData.telephoneNumber}
              onChange={handleInputChange}  
            />) :<Link to={`tel:${user.telephoneNumber}`}>{user.telephoneNumber}</Link>} </CardText></Col>
                {!isEditing && <Col><CardText><strong>Termeni acceptați:</strong> {user.termsAccepted ? "Da" : "Nu"}</CardText></Col>}
                <Col><CardText><strong>{!isEditing ? "Sesiuni active curente: " : "Maxim sesiuni active: "}</strong>{isEditing ? (
                  <Input
                  className='w-auto'
                    type="number"
                    name="maxActiveSessions"
                    value={formData.maxActiveSessions}
                    onChange={handleInputChange}
                  />
                ):<> {user.currentActiveSessions}/{user.maxActiveSessions}</>}</CardText></Col>
               </Row>
               <Row>
                <Col><CardText><strong>IDNO:</strong> {isEditing ? (  <Input
              className='w-auto'
              type="text"
              name="idno"
              value={formData.idno}
              onChange={handleInputChange}  
            />) : user.idno}</CardText></Col>
                {!isEditing && <Col><CardText><strong>Număr card salvat:</strong> {user.savedCardNumber}</CardText></Col>}
                <Col><CardText><strong>Tip persoană:</strong> {isEditing ? (
                  <Input
                  className='w-auto'
                    type="select"
                    name="personType"
                    value={formData.personType ? 'true' : 'false'}
                    onChange={(e) => handleInputChange({
                      target: { name: 'personType', value: e.target.value === 'true' }
                    })}
                  >
                    <option value="true">Juridică</option>
                    <option value="false">Fizică</option>
                  </Input> ) : user.personType ? 'Juridică' : 'Fizică'}</CardText></Col>
               </Row>
               {!isEditing && <Row>
                <Col><CardText><strong>Număr de calcule:</strong> {user.numberOfCalcs}</CardText></Col>
                <Col><CardText><strong>Calcule achitate:</strong> {user.numberOfPaidCalcs}</CardText></Col>
                <Col><CardText><strong>Calcule importate:</strong> {user.numberOfImportedCalcs}</CardText></Col>
               </Row>}
               <Row>
  <Col>
    <h6>{isEditing ? <>Max Subconturi <Input
                    className='w-auto'
                    type="number"
                    name="maxSubUsers"
                    value={formData.maxSubUsers}
                    onChange={handleInputChange}
                  /></> : <>Subconturi {user.subUsersCount}/{user.maxSubUsers}:</>}</h6>
    {!isEditing && user.subUsers && user.subUsers.length > 0 ? (
      <ul style={{ maxHeight: '120px', overflowY: 'auto', listStyleType: 'none', paddingLeft: '0' }}>
        {user.subUsers.map((subUser, index) => (
          <li key={subUser.id} style={{ padding: '8px 0', borderBottom: '1px solid #ddd' }}>
            <strong>ID:</strong> {subUser.id}, <strong>Email:</strong> {subUser.email}
          </li>
        ))}
      </ul>
    ) : !isEditing && (
      <p>Nu există subconturi disponibile.</p>
    )}
  </Col>
</Row>


              </Row>
              <Row className="d-md-none">
            
                {!isEditing && <><CardText><strong>Email:</strong> {user.email}</CardText>
                <CardText><strong>Rol:</strong> {user.role}</CardText>
                <CardText><strong>Tokenuri:</strong> {user.balance}</CardText>
               
            
                <CardText><strong>Status abonament:</strong> {user.subscriptionStatus ? "Activ: " + (user.subscription.plan.id == "lun20" ? "Lunar" :user.subscription.plan.id == "sem80" ? "Semestrial" : "Anual") : user.subscription && user.subscription.status  == "pending" ? "In asteptare" : "Inactiv"}</CardText>
                <CardText><strong>Ultima autentificare:</strong> {new Date(user.lastLoginTime).toLocaleString()}</CardText>
                <CardText><strong>Data creării:</strong> {new Date(user.creationDate).toLocaleString()}</CardText></>}
               
               
                <CardText><strong>Telefon:</strong>{isEditing ? (  <Input
              className='w-auto'
              type="text"
              name="telephoneNumber"
              value={formData.telephoneNumber}
              onChange={handleInputChange}  
            />) :<Link to={`tel:${user.telephoneNumber}`}>{user.telephoneNumber}</Link>} </CardText>
                {!isEditing && <CardText><strong>Termeni acceptați:</strong> {user.termsAccepted ? "Da" : "Nu"}</CardText>}
                <CardText><strong>{!isEditing ? "Sesiuni active curente: " : "Maxim sesiuni active: "}</strong>{isEditing ? (
                  <Input
                  className='w-auto'
                    type="number"
                    name="maxActiveSessions"
                    value={formData.maxActiveSessions}
                    onChange={handleInputChange}
                  />
                ):<> {user.currentActiveSessions}/{user.maxActiveSessions}</>}</CardText>
               
               
                <CardText><strong>IDNO:</strong> {isEditing ? (  <Input
                  className='w-auto'
                  type="text"
                  name="idno"
                  value={formData.idno}
                  onChange={handleInputChange}  
                />) : user.idno}</CardText>
                {!isEditing && <CardText><strong>Număr card salvat:</strong> {user.savedCardNumber}</CardText>}
                <CardText><strong>Tip persoană:</strong> {isEditing ? (
                  <Input
                  className='w-auto'
                    type="select"
                    name="personType"
                    value={formData.personType ? 'true' : 'false'}
                    onChange={(e) => handleInputChange({
                      target: { name: 'personType', value: e.target.value === 'true' }
                    })}
                  >
                    <option value="true">Juridică</option>
                    <option value="false">Fizică</option>
                  </Input> ) : user.personType ? 'Juridică' : 'Fizică'}</CardText>
               
                    {!isEditing && 
                    <>                <CardText><strong>Număr de calcule:</strong> {user.numberOfCalcs}</CardText>
                    <CardText><strong>Calcule achitate:</strong> {user.numberofPaidCalcs}</CardText>
                    <CardText><strong>Calcule importate:</strong> {user.numberOfImportedCalcs}</CardText></>}
                <CardText>
                <h6>{isEditing ? <>Max Subconturi <Input
                    className='w-auto'
                    type="number"
                    name="maxSubUsers"
                    value={formData.maxSubUsers}
                    onChange={handleInputChange}
                  /></> : <>Subconturi {user.subUsersCount}/{user.maxSubUsers}:</>}</h6>
    {!isEditing && user.subUsers && user.subUsers.length > 0 ? (
      <ul style={{ maxHeight: '120px', overflowY: 'auto', listStyleType: 'none', paddingLeft: '0' }}>
        {user.subUsers.map((subUser, index) => (
          <li key={subUser.id} style={{ padding: '8px 0', borderBottom: '1px solid #ddd' }}>
            <strong>ID:</strong> {subUser.id}, <strong>Email:</strong> {subUser.email}
          </li>
        ))}
      </ul>
    ) : !isEditing && (
      <p>Nu există subconturi disponibile.</p>
    )}
                </CardText>
              </Row>
        </CardBody>
            <CardFooter>
                <Row className="d-none d-md-flex">
                <Col><Button color="primary" onClick={toggleGenerateTokensModal}>Generează tokenuri</Button></Col>
                <Col>
                {user.isSuperaccount ? <Button color="secondary" onClick={cancelSuperAccount}>Anulează supercont</Button>
                : <Button color="info" onClick={activateSuperAccount}>Activează supercont</Button>}

                </Col>
                {user.subscription == null 
                ?<Col><Button color="primary" onClick={toggleSubscriptionModal}>Activează abonament</Button></Col>
                :<Col><Button color="secondary" onClick={cancelSubscription}>Anulează abonament</Button></Col>}
                </Row>
                <Row className="d-none d-md-flex" style={{paddingTop: "10px"}}>
                <Col><Button color="secondary" onClick={toggleSuspendUserModal}>Suspenda utilizator</Button></Col>
                <Col><Button color="secondary" onClick={handleUserDelete}>Elimina utilizator</Button></Col>
                <Col><Button color="primary" onClick={toggleCalculationsModal}>Vezi calcule</Button></Col>
                </Row>
                <Row className="d-none d-md-flex" style={{paddingTop: "10px"}}>
                  <Col><Button color="primary" onClick={toggleTransactionsModal}>Vezi tranzactii</Button></Col>
                  <Col><Button color="primary" onClick={notifyUser}>Notifica Utilizator</Button></Col>
                  <Col></Col>
                </Row>
                <Row className="d-md-none">
                <Button color="primary" onClick={toggleGenerateTokensModal} style={{ marginBottom: '10px' }}>Generează tokenuri</Button>
                <Button color="secondary" onClick={toggleSuspendUserModal} style={{ marginBottom: '10px' }}>Suspenda</Button>
                <Button color="secondary" onClick={handleUserDelete} style={{ marginBottom: '10px' }}>Elimina utilizator</Button>
                <Button color="primary" onClick={toggleCalculationsModal}style={{ marginBottom: '10px' }}>Vezi calcule</Button>
                <Button color="primary" onClick={toggleTransactionsModal}style={{ marginBottom: '10px' }}>Vezi tranzactii</Button>
                {user.isSuperaccount ? <Button color="secondary" style={{ marginBottom: '10px' }} onClick={cancelSuperAccount}>Anulează supercont</Button>
                : <Button color="info" style={{ marginBottom: '10px' }} onClick={activateSuperAccount}>Activează supercont</Button>}
                 {user.subscription == null 
                ?<Button color="primary" onClick={toggleSubscriptionModal}style={{ marginBottom: '10px' }}>Activează abonament</Button>
                :<Button color="secondary" onClick={cancelSubscription}style={{ marginBottom: '10px' }}>Anulează abonament</Button>}
                </Row>
            </CardFooter>
    </Card>

    <GenerateTokensModal
        modal={generateTokensModal}
        toggle={toggleGenerateTokensModal}
        userId={user.id}
    />
        <SuspendUserModal
        modal={suspendUserModal}
        toggle={toggleSuspendUserModal}
        userId={user.id}
    />
      <CalculationsModal
        modal={calculationsModal}
        toggle={toggleCalculationsModal}
        userId={user.id}
    />
      <TransactionsModal
        modal={transactionsModal}
        toggle={toggleTransactionsModal}
        userId={user.id}
    />
          <SubscriptionModal
        modal={subscriptionModal}
        toggle={toggleSubscriptionModal}
        userId={user.id}
    />

</Container>
  )
}
