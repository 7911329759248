import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import userService from '../../../Services/UserService';
import UserCard from '../../General/Users/UserCard';

export default function UserPage() {
  const { userId } = useParams(); // Extract userID from the URL
  const [user, setUser] = useState(null); // State to hold the user data

  const fetchUser = async () => {
    try {
      const fetchedUser = await userService.getUser(userId); // Adjust this function to accept userID
      setUser(fetchedUser);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  useEffect(() => {
    // Check if user is passed via stat
      fetchUser(); // Fetch user when page is loaded

  }, [userId]); // Re-run when location.state or userId changes

  // Refetch the user when needed (e.g., after an update or action)
  const refetchUser = () => {
    fetchUser();
  };
  return (
    <Fragment>
      {user ? <UserCard user={user} refetchUser={refetchUser}/> : <p>Loading user details...</p>}
    </Fragment>
  );
}
