import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import DataTable from "react-data-table-component";

const UserSelection = ({ users, setUsers, userIds, setUserIds }) => {
  const [search, setSearch] = useState(""); // Search term
  const [selectedUsers, setSelectedUsers] = useState([]); // Full user objects for selected users

  const handleRowClick = (row) => {
    if (!userIds.includes(row.id)) {
      // Add the user to selected lists
      setUserIds([...userIds, row.id]);
      setSelectedUsers([...selectedUsers, row]);
      // Remove the user from available list
      setUsers(users.filter((user) => user.id !== row.id));
    }
  };

  const handleRemoveUser = (userId) => {
    if (userIds.includes(userId)) {
      // Remove user from selected lists
      setUserIds(userIds.filter((id) => id !== userId));

      // Find the user to remove and then reinstate them in the original position
      const removedUser = selectedUsers.find((user) => user.id === userId);
      setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));

      // Reinsert the removed user in the correct position in the available users list
      const restoredUsers = [...users];
      const originalIndex = users.findIndex((user) => user.id === removedUser.id);
      if (originalIndex !== -1) {
        // Insert at the same position where it was originally
        restoredUsers.splice(originalIndex, 0, removedUser);
      } else {
        // If not found, push to the end (fallback)
        restoredUsers.push(removedUser);
      }
      setUsers(restoredUsers);
    }
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    { name: "ID", selector: (row) => row.id, sortable: true }, // ID column
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Email", selector: (row) => row.email, sortable: true },
  ];

  const selectedColumns = [
    { name: "ID", selector: (row) => row.id, sortable: true }, // ID column
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Email", selector: (row) => row.email, sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => handleRemoveUser(row.id)}
          style={{ padding: "2px 6px", fontSize: "12px" }} // Adjust button padding and font size
        >
          Sterge
        </Button>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "25px", // Thinner rows
      },
    },
    headCells: {
      style: {
        fontSize: "14px", // Font remains the same
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        fontSize: "12px", // Font remains the same
      },
    },
  };

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      {/* Available Users Table */}
      <div style={{ flex: 1 }}>
        <h3>Utilizatori disponibili</h3>
        <Input
          type="text"
          placeholder="Caută după nume"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="mb-3"
        />
        <DataTable
          columns={columns}
          data={filteredUsers}
          pagination
          highlightOnHover
          onRowClicked={handleRowClick} // Handle row click
          customStyles={customStyles}
        />
      </div>

      {/* Selected Users Table */}
      <div style={{ flex: 1 }}>
        <h3>Utilizatori selectați</h3>
        <DataTable
          columns={selectedColumns}
          data={selectedUsers} // Use selectedUsers for display
          pagination
          highlightOnHover
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default UserSelection;
