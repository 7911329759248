import moment from 'moment'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Col, Container, Row } from 'reactstrap'
import userService from '../../../Services/UserService'

export default function TransactionCard({transaction, updateTransaction}) {
  const [signed, setSigned] = useState(transaction.signed);
  const [billed, setBilled] = useState(transaction.billed);
  const signTransaction = async () => {
    try {
      const response = await userService.signTransaction(transaction.id);
      const updatedTransaction = { ...transaction, signed: true };
      updateTransaction(updatedTransaction);
      setSigned(true);
      toast.success("Tranzactie semnata cu success");
    } catch (error) {
      toast.error("Eroare la semnare tranzactie");
    }
  }
  const billTransaction = async () => {
    try {
      const response = await userService.billTransaction(transaction.id);
      const updatedTransaction = { ...transaction, billed: true };
      updateTransaction(updatedTransaction);
      setBilled(true);
      toast.success("Tranzactie facturata cu success");
    } catch (error) {
      toast.error("Eroare la facturare tranzactie");
    }
  }
  return (
    <Container>
      <Card>
        <CardBody style={{ width: '100%' }}>
          <CardTitle tag="h5">{String(transaction.id).padStart(5, '0')}</CardTitle>
          <Row className="d-none d-md-flex">
            <Row>
            <Col><CardText><strong>Data tranzactiei:</strong> {moment(transaction.timestamp).format('DD.MM.YYYY')}</CardText></Col>
              <Col><CardText><strong>Email:</strong> {transaction.email}</CardText></Col>
              <Col><CardText><strong>Nume:</strong> {transaction.name}</CardText></Col>
            </Row>
            <Row>
              <Col><CardText><strong>Suma:</strong> {transaction.amount}</CardText></Col>
              <Col><CardText><strong>Statut:</strong> {transaction.status}</CardText></Col>
              <Col><CardText><strong>Semnat:</strong> {signed ? "Da" : "Nu"}</CardText></Col>
            </Row>
          </Row>
        </CardBody>
        <CardFooter>
          {
            !signed && billed ?
            <Button color="primary" onClick={signTransaction}>
                Semneaza
            </Button> :
            <Button color="primary" onClick={billTransaction}>
            Factureaza
        </Button>
          }
        </CardFooter>
      </Card>
    </Container>
  )
}
