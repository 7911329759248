import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import '../../../assets/custom_styles.css';
import { P } from '../../../AbstractElements';
import transactionColumns from '../../../assets/data/transactionColumns';
import { useAppData } from '../../../context/AppDataContext';
import userService from '../../../Services/UserService';
import SubscriptionActivationModal from '../../Common/Modal/SubscriptionActivationModal';
import ReactTable from 'react-data-table-component';
import { useMemo } from 'react';
import FilterComponent from '../../Common/Component/FilterComponent';
import TransactionCard from './TransactionCard';
export default function TransactionsTable({pending}) {
    const [transactionId,setTransactionId] = useState('');
    const [transaction, setTransaction] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filterBy, setFilterBy] = useState(pending ? null :'name');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [cardModal, setCardModal] = useState(false);
    const toggleCardModal = () => setCardModal(!cardModal);
    const{transactions, users, setTransactions} = useAppData();

    const [showType, setShowType] = useState('pending');

    const filteredTransactions = useMemo(() => {
      if (!transactions) return [];
    
      let filtered = transactions;
    
      // Apply text-based filtering
      if (filterBy === "name") {
        filtered = filtered.filter(transaction => 
          transaction.name && transaction.name.toLowerCase().includes(filterText.toLowerCase())
        );
      } else if (filterBy === "email") {
        filtered = filtered.filter(transaction => 
          transaction.email && transaction.email.toLowerCase().includes(filterText.toLowerCase())
        );
      } else if (filterBy === "transactionId") {
        filtered = filtered.filter(transaction => 
          transaction.id && transaction.id.toString().includes(filterText.trim())
        );
      }
    
      // Apply status-based filtering only for pending transactions
      if (pending) {
        if (showType == "pending") {
          filtered = filtered.filter(transaction => transaction.status == "pending" && (transaction.cardNumber == null && transaction.amount >= '300'));
        } else if (showType == "toInvoice") {
          filtered = filtered.filter(transaction => transaction.status == "successful" && (transaction.cardNumber == null && transaction.amount >= '300') && !transaction.billed);
        } else if (showType == "toSign") {
          filtered = filtered.filter(transaction => transaction.status == "successful" && (transaction.cardNumber == null && transaction.amount >= '300') && transaction.billed && !transaction.signed);
        }
      }
    
      return filtered;
    }, [transactions, filterText, filterBy, showType, pending]);
    
  
    const handleClearFilter = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
  
    const subHeaderComponentMemo = useMemo(() => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Input
          className='w-auto'
          type="select"
          name="filterBy"
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
        >
          <option value="name">Nume</option>
          <option value="email">Email</option>
          <option value="transactionId">Ordin de plata</option>
        </Input>
        <FilterComponent 
          onFilter={e => setFilterText(e.target.value)} 
          onClear={handleClearFilter} 
          filterText={filterText} 
          filterBy={filterBy}
        />
      </div>
    ), [filterText, resetPaginationToggle, filterBy]);


    const successTransactionsCount = () => {
        if (!transactions) return 0; // Return 0 if data is null
    
        // Filter the users based on the 'enabled' field
        const enabledTransactions = transactions.filter(transaction => transaction.status == "successful");
        return enabledTransactions.length; // Return the count of enabled users
    };
    const pendingTransactionsCount = () => {
        if (!transactions) return 0; // Return 0 if data is null
    
        // Filter the users based on the 'enabled' field
        const enabledTransactions = transactions.filter(transaction => transaction.status == "pending");
        return enabledTransactions.length; // Return the count of enabled users
    };
    const updateTransaction = (updatedTransaction) => {
      setTransaction(updatedTransaction);
    
      // Also update transactions in the table
      setTransactions((prevTransactions) =>
        prevTransactions.map((t) => (t.id === updatedTransaction.id ? updatedTransaction : t))
      );
    };
    
    const conditionalRowStyles = [
      {
        when: row => true,
        style: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
        
      },
    
    ];
    const handleRowClick = (row) => {
      if(row.status == "pending"){
        setTransactionId(row.id);
      toggle();
      }else{
        setTransaction(row);
        toggleCardModal();
      }
    }
    const activateSubscription = (e) =>{
      e.preventDefault();
      try {
        userService.activateSubscription(transactionId);
        toast.success('Abonament activat cu succes!', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        filteredTransactions = filteredTransactions.filter(transaction => transaction.id !== transactionId);
        toggle();
      } catch (error) {
        toast.error('Eroare la activare abonament', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

  return (
    <>
            <Card>
            <CardHeader>
                {pending ? <P>Tranzactii in asteptare: {pendingTransactionsCount()}</P>
                : <P>Tranzactii finalizate: {successTransactionsCount()}</P>}
               {pending &&  <div className='switch-box'>
                  <button className={showType == 'pending' ? 'switch-card active-switch-card' : 'switch-card'} type='button' onClick={()=>setShowType('pending')}>
                    In asteptare
                  </button>
                  <button className={showType == 'toInvoice' ? 'switch-card active-switch-card' : 'switch-card'}  type='button' onClick={()=>setShowType('toInvoice')}>
                    Spre facturare
                  </button>
                  <button className={showType == 'toSign' ? 'switch-card active-switch-card' : 'switch-card'}  type='button' onClick={()=>setShowType('toSign')}>
                    Spre semnare
                  </button>
                </div>}
                </CardHeader>
                <CardBody>
                <ReactTable
                    columns={transactionColumns}
                    data={filteredTransactions}
                    noHeader
                    pagination
                    subHeader
                    highlightOnHover
                    conditionalRowStyles={conditionalRowStyles}
                    onRowClicked={handleRowClick}
                    paginationPerPage={10} 
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    defaultSortAsc={false}
                    defaultSortFieldId={6}
                    subHeaderComponent={!pending ? subHeaderComponentMemo : null}
                />
                </CardBody>
            </Card>
        <SubscriptionActivationModal
        modal = {modal}
        handleSubmit = {activateSubscription}
        toggle = {toggle}
        transactionId = {transactionId}
        />
        <Modal isOpen={cardModal} toggle={toggleCardModal} size="xl">
        <ModalHeader toggle={toggleCardModal}></ModalHeader>
          <ModalBody>
            <TransactionCard transaction={transaction} updateTransaction={updateTransaction}/>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleCardModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
    </>
    
  )
}
